// Global import(s)
import Keycloak from 'keycloak-js';
import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const KeycloakContext = createContext({
  initialized: false,
  keycloak: null,
});

const KeycloakProvider = ({ children, config }) => {
  const [initialized, setInitialized] = useState(false);
  const [keycloak, setKeycloak] = useState(null);

  useEffect(() => {
    const keycloakInstance = new Keycloak(config);

    (async () => {
      const isAuthenticated = await keycloakInstance?.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      });

      if (isAuthenticated) {
        setKeycloak(keycloakInstance);
        setInitialized(true);
      } else {
        await keycloakInstance.login();
      }
    })();
  }, [config]);

  return (
    <KeycloakContext.Provider value={{ initialized, keycloak }}>
      {children}
    </KeycloakContext.Provider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    realm: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
  }).isRequired,
};

const useKeycloak = () => useContext(KeycloakContext);

export { KeycloakContext, KeycloakProvider, useKeycloak };
